





































































































.scales-settings-visibility {
  padding: 5px 10px 5px 0;
  cursor: pointer;
}
